import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { StyledEngineProvider } from "@mui/material/styles";
import { Provider } from "react-redux";
import store from "./store.js";
import "./App.css";
import CommonContext from "./context/CommonContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <StyledEngineProvider injectFirst>
        <CommonContext>
      <Provider store={store}>
          <App />
      </Provider>
        </CommonContext>
    </StyledEngineProvider>
  </BrowserRouter>
);
