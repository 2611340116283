import { 
    BIZ_INCOME_REQUEST,
    BIZ_INCOME_SUCCESS,
    BIZ_INCOME_FAIL   
  } from "../constants/MyBizIncomeStatement";

  export const myBizIncomeSheetListReducer = (state = {
    mybizinscomesheetdatalist: []
  }, action) => {
    switch (action.type) {
      case BIZ_INCOME_REQUEST:
        return {
            mybizinscomesheetloading: true, mybizinscomesheetdatalist: []
        }
        case BIZ_INCOME_SUCCESS:
          return {
            mybizinscomesheetloading: false,
            mybizinscomesheetdatalist: action.payload,
          }
          case BIZ_INCOME_FAIL:
            return {
                mybizinscomesheetloading: false, error: action.payload
            }
            default:
              return state
    }
  }