export const BUSINESS_LIST_REQUEST = "BUSINESS_LIST_REQUEST";
export const BUSINESS_LIST_SUCCESS = "BUSINESS_LIST_SUCCESS";
export const BUSINESS_LIST_FAIL = "BUSINESS_LIST_FAIL";

export const REVENUE_COMPANY_NUMBER_LIST_REQUEST  = "BUSINESS_TYPE_COMPANY_NUMBER_LIST_REQUEST"
export const REVENUE_COMPANY_NUMBER_LIST_SUCCESS  = "BUSINESS_TYPE_COMPANY_NUMBER_LIST_SUCCESS"
export const REVENUE_COMPANY_NUMBER_LIST_FAIL     = "BUSINESS_TYPE_COMPANY_NUMBER_LIST_FAIL"

export const BUSINESS_TYPE_COMPANY_NUMBER_LIST_REQUEST = "BUSINESS_TYPE_COMPANY_NUMBER_LIST_REQUEST"
export const BUSINESS_TYPE_COMPANY_NUMBER_LIST_SUCCESS = "BUSINESS_TYPE_COMPANY_NUMBER_LIST_SUCCESS"
export const BUSINESS_TYPE_COMPANY_NUMBER_LIST_FAIL    = "BUSINESS_TYPE_COMPANY_NUMBER_LIST_FAIL"

export const LOCATION_COMPANY_NUMBER_LIST_REQUEST = "BUSINESS_TYPE_COMPANY_NUMBER_LIST_REQUEST"
export const LOCATION_COMPANY_NUMBER_LIST_SUCCESS = "BUSINESS_TYPE_COMPANY_NUMBER_LIST_SUCCESS"
export const LOCATION_COMPANY_NUMBER_LIST_FAIL    = "BUSINESS_TYPE_COMPANY_NUMBER_LIST_FAIL"

export const YEAR_COMPANY_NUMBER_LIST_REQUEST     = "BUSINESS_TYPE_COMPANY_NUMBER_LIST_REQUEST"
export const YEAR_COMPANY_NUMBER_LIST_SUCCESS     = "BUSINESS_TYPE_COMPANY_NUMBER_LIST_SUCCESS"
export const YEAR_COMPANY_NUMBER_LIST_FAIL        = "BUSINESS_TYPE_COMPANY_NUMBER_LIST_FAIL"

export const NAICS_COMPANY_NUMBER_LIST_REQUEST    = "NAICS_COMPANY_NUMBER_LIST_REQUEST";
export const NAICS_COMPANY_NUMBER_LIST_SUCCESS    = "NAICS_COMPANY_NUMBER_LIST_SUCCESS";
export const NAICS_COMPANY_NUMBER_LIST_FAIL       = "NAICS_COMPANY_NUMBER_LIST_FAIL";

export const MULTI_COMPANY_NUMBER_LIST_REQUEST         = "MULTI_COMPANY_NUMBER_LIST_REQUEST"
export const MULTI_COMPANY_NUMBER_LIST_SUCCESS         = "MULTI_COMPANY_NUMBER_LIST_SUCCESS"
export const MULTI_COMPANY_NUMBER_LIST_FAIL            = "MULTI_COMPANY_NUMBER_LIST_FAIL"

export const ALL_MULTI_COMPANY_NUMBER_LIST_REQUEST     = "ALL_MULTI_COMPANY_NUMBER_LIST_REQUEST"
export const ALL_MULTI_COMPANY_NUMBER_LIST_SUCCESS     = "ALL_MULTI_COMPANY_NUMBER_LIST_SUCCESS"
export const ALL_MULTI_COMPANY_NUMBER_LIST_FAIL        = "ALL_MULTI_COMPANY_NUMBER_LIST_FAIL"

export const YEAR_MULTI_COMPANY_NUMBER_LIST_REQUEST    = "YEAR_MULTI_COMPANY_NUMBER_LIST_REQUEST";
export const YEAR_MULTI_COMPANY_NUMBER_LIST_SUCCESS    = "YEAR_MULTI_COMPANY_NUMBER_LIST_SUCCESS";
export const YEAR_MULTI_COMPANY_NUMBER_LIST_FAIL       = "YEAR_MULTI_COMPANY_NUMBER_LIST_FAIL";

export const FILTER_VALUE_LIST_REQUEST = "FILTER_VALUE_LIST_REQUEST"
export const FILTER_VALUE_LIST_SUCCESS = "FILTER_VALUE_LIST_SUCCESS"
export const FILTER_VALUE_LIST_FAIL    = "FILTER_VALUE_LIST_FAIL"

export const GEO_CODE_FILTER_VALUE_LIST_REQUEST = "GEO_CODE_FILTER_VALUE_LIST_REQUEST"
export const GEO_CODE_FILTER_VALUE_LIST_SUCCESS = "GEO_CODE_FILTER_VALUE_LIST_SUCCESS"
export const GEO_CODE_FILTER_VALUE_LIST_FAIL    = "GEO_CODE_FILTER_VALUE_LIST_FAIL"