import React, { lazy } from "react";

const Home = lazy(() => import("./components/Home/index.jsx"));
const ContactPage = lazy(() => import("./components/ContactUs/index.jsx"));
const SignInHome = lazy(() => import("./components/SignIn/index.jsx"));
const ProfileSec = lazy(() => import("./components/ProfilePages/index.jsx"));
const BusinessInfoPage = lazy(() =>
  import("./components/BuinessInformation/index.jsx")
);
const BenchmarkPremiumPage = lazy(() =>
  import("./components/BenchmarkBusinessPremium/index.jsx")
);
const BenchmarkBusinessPage = lazy(() =>
  import("./components/BenchmarkBusiness/index.jsx")
);
const Glossary = lazy(() =>
  import("./components/SubComponent/BasicChart/Glossary/Glossary.jsx")
);
const IncomeStatement = lazy(() =>
  import(
    "./components/SubComponent/BasicChart/IncomeStatement/IncomeStatement.jsx"
  )
);
const BalanceSheet = lazy(() =>
  import("./components/SubComponent/BasicChart/BalanceSheet/BalanceSheet.jsx")
);
const Profitability = lazy(() =>
  import("./components/SubComponent/BasicChart/Profitability/Profitability.jsx")
);
const CostOfSale = lazy(() =>
  import("./components/SubComponent/BasicChart/CostOfSale/CostOfSale.jsx")
);
const OperatingExpenses = lazy(() =>
  import(
    "./components/SubComponent/BasicChart/OperatingExpenses/OperatingExpenses.jsx"
  )
);
const ProfitabilityTwo = lazy(() =>
  import(
    "./components/SubComponent/BasicChart/ProfitabilityTwo/ProfitabilityTwo.jsx"
  )
);
const BalanceSheetAnalysis = lazy(() =>
  import(
    "./components/SubComponent/BasicChart/BalanceSheetAnalysis/BalanceSheetAnalysis.jsx"
  )
);
const LeverageDebtOfEquaty = lazy(() =>
  import(
    "./components/SubComponent/BasicChart/LeverageDebtOfEquity/LeverageDebtOfEquaty.jsx"
  )
);
const LeverageDebtCApitalization = lazy(() =>
  import(
    "./components/SubComponent/BasicChart/LeverageDebtOfCapitalization/LeverageDebtCApitalization.jsx"
  )
);
const Liquidity = lazy(() =>
  import("./components/SubComponent/BasicChart/Liquidity/Liquidity.jsx")
);
const Efficiency = lazy(() =>
  import("./components/SubComponent/BasicChart/Efficiency/Efficiency.jsx")
);
const UpperEfficiency = lazy(() =>
  import(
    "./components/SubComponent/BasicChart/UpperEfficeincy/UpperEfficiency.jsx"
  )
);
const BusinessForm = lazy(() =>
  import("./components/BuinessInformation/BusinessForm.jsx")
);
const SignUpPage = lazy(() => import("./components/SingnUp/signUp.jsx"));
const BenchmarkYourBusinessFilter = lazy(() =>
  import(
    "./components/SubComponent/BasicChart/BenchMarkBusinessFilter/BenchmarkBusinessFilterChart"
  )
);
const BusinessEditPage = lazy(() =>
  import("./components/BuinessInformation/BusinessEditPage.jsx")
);
const BenchmarkBusinessIframe = lazy(() =>
  import("./components/BenchmarkBusiness/BenchmarkBusinessIframe.jsx")
);
const BalanceSheetEditPage = lazy(() =>
  import("./components/BuinessInformation/BalanceSheetEditPage.jsx")
);
const IncomeStatementEditPage = lazy(() =>
  import("./components/BuinessInformation/IncomeStatementEditPage.jsx")
);
const BenchmarkBusinessPremiumIframe = lazy(() =>
  import(
    "./components/BenchmarkBusinessPremium/BenchmarkBusinessPremiumIframe.jsx"
  )
);
const IncomeStatementPremium = lazy(() =>
  import(
    "./components/PremiumSubComponent/BasicChart/IncomeStatementPremium/IncomeStatementpremium.jsx"
  )
);
const BalanceSheetPremium = lazy(() =>
  import(
    "./components/PremiumSubComponent/BasicChart/BalanceSheetPremium/BalanceSheet.jsx"
  )
);
const ProfitabilityPremium = lazy(() =>
  import(
    "./components/PremiumSubComponent/BasicChart/ProfibilityPremium/ProfibilityPremium.jsx"
  )
);
const CostofSalesPremium = lazy(() =>
  import(
    "./components/PremiumSubComponent/BasicChart/CostofSalesPremium/CostofSalesPremium.jsx"
  )
);
const OperatingExpensesPremiumOne = lazy(() =>
  import(
    "./components/PremiumSubComponent/BasicChart/OperatingExpensesPremiumOne/OperatingExpensesPremiumOne.jsx"
  )
);
const OperatingExpensesPremiumTwo = lazy(() =>
  import(
    "./components/PremiumSubComponent/BasicChart/OperatingExpensesPremiumTwo/OperatingExpensesPremiumTwo.jsx"
  )
);
const ProfibilityPremiumTwo = lazy(() =>
  import(
    "./components/PremiumSubComponent/BasicChart/ProfibilityPremiumTwo/ProfibilityPremiumTwo.jsx"
  )
);
const BalanceSheetAnalysisPremium = lazy(() =>
  import(
    "./components/PremiumSubComponent/BasicChart/BalanceSheetAnalysisPremium/BalanceSheetAnalysisPremium.jsx"
  )
);
const LeverageDebtOfEquityPremium = lazy(() =>
  import(
    "./components/PremiumSubComponent/BasicChart/LeverageDebtOfEquityPremium/LeverageDebtOfEquityPremium.jsx"
  )
);
const LeverageDebtOfCapitalizationPremium = lazy(() =>
  import(
    "./components/PremiumSubComponent/BasicChart/LeverageDebtOfCapitalizationPremium/LeverageDebtOfCapitalizationPremium.jsx"
  )
);
const LiquidityPremium = lazy(() =>
  import(
    "./components/PremiumSubComponent/BasicChart/LiquidityPremium/LiquidityPremium.jsx"
  )
);
const EfficiencyPremium = lazy(() =>
  import(
    "./components/PremiumSubComponent/BasicChart/EfficiencyPremium/EfficiencyPremium.jsx"
  )
);
const GlossaryPremium = lazy(() =>
  import(
    "./components/PremiumSubComponent/BasicChart/GlossaryPremium/GlossaryPremium.jsx"
  )
);
const PlanList = lazy(() =>
  import("./components/BenchmarkBusinessPremium/PlanList.jsx")
);
const Success = lazy(() =>
  import("./components/BenchmarkBusinessPremium/Success.jsx")
);
const Cancel = lazy(() =>
  import("./components/BenchmarkBusinessPremium/Cancel.jsx")
);
const PremiumCheck = lazy(() =>
  import("./components/BenchmarkBusinessPremium/PremiumCheck.jsx")
);
const FaqPage = lazy(() => import("./components/FAQ/FaqPage.jsx"));
const TermCondition = lazy(() => import("./components/TermCondition.jsx"));
const ResetPassword = lazy(() =>
  import("./components/SingnUp/ResetPassword.jsx")
);
const PrivacyPolicy = lazy(() => import("./components/PrivacyPolicy.jsx"));
const ButtonLoader = lazy(() =>
  import("./components/WebAssests/ButtonLoader.jsx")
);
const DealhubForm = lazy(() => import("./Dealhub/Deals/DealhubForm.jsx"));
const AddMyDeal = lazy(() => import("./Dealhub/Deals/AddNewDeal.jsx"));
const DealhubTable = lazy(() => import("./Dealbub/Filters/DealhubTable.jsx"));
const EditDealHub =  lazy (()=>import("./Dealhub/Deals/EditDealHubForms.jsx"))

// const BenchmarkBusinessPremium = (
//   // <PremiumCheck>
//   <BenchmarkBusinessPremiumIframe />
//   // </PremiumCheck>
// );
const coreRoutes = [
  {
    path: "/",
    title: "Home",
    component: Home,
  },
  {
    path: "/contact",
    title: "Home",
    component: ContactPage,
  },
  {
    path: "/resetpassword",
    title: "Home",
    component: ResetPassword,
  },
  {
    path: "/signinpage",
    title: "Home",
    component: SignInHome,
  },
  {
    path: "/profile",
    title: "Home",
    component: ProfileSec,
  },
  {
    path: "/businessinfo",
    title: "Home",
    component: BusinessInfoPage,
  },
  {
    path: "/businessinfo/:businessId",
    title: "Home",
    component: BusinessEditPage,
  },
  {
    path: "/balancesheet/edit/:businessId",
    title: "Home",
    component: BalanceSheetEditPage,
  },
  {
    path: "/benchmarkbusiness",
    title: "Home",
    component: BenchmarkBusinessIframe,
  },
  {
    path: "/businessform",
    title: "Home",
    component: BusinessForm,
  },
  {
    path: "/tsform",
    title: "Home",
    component: DealhubForm,
  },
  {
    path: "/tsform/:dealId",
    title: "Home",
    component: EditDealHub,
  },
  {
    path: "/signuppage",
    title: "Home",
    component: SignUpPage,
  },
  {
    path: "/incomestatement",
    title: "Home",
    component: IncomeStatement,
  },
  {
    path: "/incomestatement/edit/:businessId",
    title: "Home",
    component: IncomeStatementEditPage,
  },
  {
    path: "/balancesheet",
    title: "Home",
    component: BalanceSheet,
  },
  {
    path: "/profitability",
    title: "Home",
    component: Profitability,
  },
  {
    path: "/costOfSale",
    title: "Home",
    component: CostOfSale,
  },
  {
    path: "/operatingExpenses",
    title: "Home",
    component: OperatingExpenses,
  },
  {
    path: "/profitabilityTwo",
    title: "Home",
    component: ProfitabilityTwo,
  },
  {
    path: "/balanceSheetAnalysis",
    title: "Home",
    component: BalanceSheetAnalysis,
  },
  {
    path: "/leverageDebtOfEquaty",
    title: "Home",
    component: LeverageDebtOfEquaty,
  },
  {
    path: "/leverageDebtCApitalization",
    title: "Home",
    component: LeverageDebtCApitalization,
  },
  {
    path: "/liquidity",
    title: "Home",
    component: Liquidity,
  },
  {
    path: "/efficiency",
    title: "Home",
    component: Efficiency,
  },
  {
    path: "/upperEfficiency",
    title: "Home",
    component: UpperEfficiency,
  },
  {
    path: "/glossary",
    title: "Home",
    component: Glossary,
  },

  {
    path: "/incomestatementpremium",
    title: "Home",
    component: IncomeStatementPremium,
  },
  {
    path: "/balancesheetpremium",
    title: "Home",
    component: BalanceSheetPremium,
  },
  {
    path: "/profitabilitypremium",
    title: "Home",
    component: ProfitabilityPremium,
  },
  {
    path: "/costofsalespremium",
    title: "Home",
    component: CostofSalesPremium,
  },
  {
    path: "/operatingexpensespremiumone",
    title: "Home",
    component: OperatingExpensesPremiumOne,
  },
  {
    path: "/operatingexpensespremiumTwo",
    title: "Home",
    component: OperatingExpensesPremiumTwo,
  },
  {
    path: "/profibilitypremiumtwo",
    title: "Home",
    component: ProfibilityPremiumTwo,
  },
  {
    path: "/balancesheetanalysispremium",
    title: "Home",
    component: BalanceSheetAnalysisPremium,
  },
  {
    path: "/leveragedebtofequitypremium",
    title: "Home",
    component: LeverageDebtOfEquityPremium,
  },
  {
    path: "/leveragedebtofcapitalizationpremium",
    title: "Home",
    component: LeverageDebtOfCapitalizationPremium,
  },
  {
    path: "/liquiditypremium",
    title: "Home",
    component: LiquidityPremium,
  },
  {
    path: "/efficiencypremium",
    title: "Home",
    component: EfficiencyPremium,
  },
  {
    path: "/glossarypremium",
    title: "Home",
    component: GlossaryPremium,
  },
  {
    path: "/success",
    title: "Home",
    component: GlossaryPremium,
  },
  {
    path: "/cancel",
    title: "Home",
    component: Cancel,
  },
  {
    path: "/faq",
    title: "Home",
    component: FaqPage,
  },
  {
    path: "/termCondition",
    title: "Home",
    component: TermCondition,
  },
  {
    path: "/privacyPolicy",
    title: "Home",
    component: PrivacyPolicy,
  },
  {
    path: "/privacyPolicy",
    title: "Home",
    component: PrivacyPolicy,
  },
  {
    path: "/addMyDeal",
    title: "Home",
    component: AddMyDeal,
  },
  {
    path: "/dealHub",
    title: "Home",
    component: DealhubTable,
  },
];
export const routes = [...coreRoutes];
//  default routes;

const checkPremiumRoutes = [
  {
    path: "/benchmarkpremium",
    title: "Home",
    component: BenchmarkBusinessPremiumIframe,
  },
  {
    path: "/benchmarkpremiumList",
    title: "Home",
    component: PlanList,
  },
];

export const PremiumRoutes = [...checkPremiumRoutes];
// export default PremiumRoutes;
