import React, { createContext, useState } from "react";

const commonValueContext = createContext(null);
export { commonValueContext };

const CommonContext = ({ children }) => {
  //   const [currentLatLnd, setCurrentLatLng] = useState({});
  const [userDetails, setUserDetails] = useState();
  const [balanceId, setBalanceId] = useState([]);
  const [balanceMessage, setBalanceMessage] = useState();
  const [incomeId, setIncomeId] = useState([]);
  const [incomeMessage, setIncomeMessage] = useState();
  const [incomeStatementData, setIncomeStatementData] = useState([]);
  const [balanceSheetData, setBalanceSheetData] = useState([]);
  const [tsIncomeStatementData, setTsIncomeStatementData] = useState([]);
  return (
    <commonValueContext.Provider
      value={{
        userDetails,
        balanceId,
        balanceMessage,
        incomeId,
        incomeMessage,
        incomeStatementData,
        tsIncomeStatementData,
        balanceSheetData,
        setBalanceId,
        setUserDetails,
        setBalanceMessage,
        setIncomeMessage,
        setIncomeId,
        setIncomeStatementData,
        setTsIncomeStatementData,
        setBalanceSheetData,
      }}
    >
      {children}
    </commonValueContext.Provider>
  );
};

export default CommonContext;
