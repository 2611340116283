
import {
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  // USER_PROFILE_FAIL,
  // USER_PROFILE_REQUEST,
  // USER_PROFILE_SUCCESS
} from '../constants/userConstants.js';

const initialState = {
  loading: false,
  currentUser: null,
  error: null,
}

export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true }

    case USER_LOGIN_SUCCESS:
      return { loading: false, loginData: action.payload }

    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload }
    case USER_LOGOUT:
      return {}
    default:
      return state
  }
}

// export const userProfileReducer = (state = {}, action) => {
//   switch (action.type) {
//     case USER_PROFILE_REQUEST:
//       return {userLoading: true }

//     case USER_PROFILE_SUCCESS:
//       return { userLoading: false, profileUploadData: action.payload }

//     case USER_PROFILE_FAIL:
//       return { userLoading: false, error: action.payload }
//     default:
//       return state
//   }
// }

export const userDetailsReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case USER_DETAILS_REQUEST:
      return { ...state, loading: true };
    case USER_DETAILS_SUCCESS:
      return { loading: false, user: action.payload };
    case USER_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state
  }
}