import {
    BALANCE_SHEET_ASSETS_REQUEST,
    BALANCE_SHEET_ASSETS_SUCCESS,
    BALANCE_SHEET_ASSETS_FAIL,
    BALANCE_SHEET_LIABILITIES_EQUITY_REQUEST,
    BALANCE_SHEET_LIABILITIES_EQUITY_SUCCESS,
    BALANCE_SHEET_LIABILITIES_EQUITY_FAIL
} from "../constants/BalanceSheetConstants.js"

// ASSETS

  export const balanceSheetAssetsListReducer = (state = {
    balancesheetassetslist: []
  }, action) => {
    switch (action.type) {
      case BALANCE_SHEET_ASSETS_REQUEST:
        return {
          balanceSheetAssetsLoading: true, balancesheetassetslist: []
        }
        case BALANCE_SHEET_ASSETS_SUCCESS:
          return {
            balanceSheetAssetsLoading: false, balancesheetassetslist: action.payload,
          }
          case BALANCE_SHEET_ASSETS_FAIL:
            return {
                balanceSheetAssetsLoading: false, error: action.payload
            }
            default:
              return state
    }
  }

  export const balanceSheetAssetsMultiListReducer = (state = {
    balancesheetassetsmultilist: []
  }, action) => {
    switch (action.type) {
      case BALANCE_SHEET_ASSETS_REQUEST:
        return {
          balanceSheetAssetsMultiLoading: true, balancesheetassetsmultilist: []
        }
        case BALANCE_SHEET_ASSETS_SUCCESS:
          return {
            balanceSheetAssetsMultiLoading: false, balancesheetassetsmultilist: action.payload,
          }
          case BALANCE_SHEET_ASSETS_FAIL:
            return {
                balanceSheetAssetsMultiLoading: false, error: action.payload
            }
            default:
              return state
    }
  }

  export const balanceSheetAssetsAllMultiListReducer = (state = {
    balancesheetassetsallmultilist: []
  }, action) => {
    switch (action.type) {
      case BALANCE_SHEET_ASSETS_REQUEST:
        return {
          balanceSheetAssetsAllMultiLoading: true, balancesheetassetsallmultilist: []
        }
        case BALANCE_SHEET_ASSETS_SUCCESS:
          return {
            balanceSheetAssetsAllMultiLoading: false, balancesheetassetsallmultilist: action.payload,
          }
          case BALANCE_SHEET_ASSETS_FAIL:
            return {
                balanceSheetAssetsAllMultiLoading: false, error: action.payload
            }
            default:
              return state
    }
  }

  export const balanceSheetAssetsYearListReducer = (state = {
    balancesheetassetsyearlist: []
  }, action) => {
    switch (action.type) {
      case BALANCE_SHEET_ASSETS_REQUEST:
        return {
          balanceSheetAssetsYearLoading: true, balancesheetassetsyearlist: []
        }
        case BALANCE_SHEET_ASSETS_SUCCESS:
          return {
            balanceSheetAssetsYearLoading: false, balancesheetassetsyearlist: action.payload,
          }
          case BALANCE_SHEET_ASSETS_FAIL:
            return {
                balanceSheetAssetsYearLoading: false, error: action.payload
            }
            default:
              return state
    }
  }

  export const balanceSheetAssetsYearMultiListReducer = (state = {
    balancesheetassetsyearmultilist: []
  }, action) => {
    switch (action.type) {
      case BALANCE_SHEET_ASSETS_REQUEST:
        return {
          balanceSheetAssetsYearMultiLoading: true, balancesheetassetsyearmultilist: []
        }
        case BALANCE_SHEET_ASSETS_SUCCESS:
          return {
            balanceSheetAssetsYearMultiLoading: false, balancesheetassetsyearmultilist: action.payload,
          }
          case BALANCE_SHEET_ASSETS_FAIL:
            return {
                balanceSheetAssetsYearMultiLoading: false, error: action.payload
            }
            default:
              return state
    }
  }

  export const balanceSheetAssetsNaiceListReducer = (state = {
    balancesheetassetsnaicelist: []
  }, action) => {
    switch (action.type) {
      case BALANCE_SHEET_ASSETS_REQUEST:
        return {
          balanceSheetAssetsNaiceLoading: true, balancesheetassetsnaicelist: []
        }
        case BALANCE_SHEET_ASSETS_SUCCESS:
          return {
            balanceSheetAssetsNaiceLoading: false, balancesheetassetsnaicelist: action.payload,
          }
          case BALANCE_SHEET_ASSETS_FAIL:
            return {
                balanceSheetAssetsNaiceLoading: false, error: action.payload
            }
            default:
              return state
    }
  }

// Liabilities && Equity

export const balanceSheetLiabilitiesAndEquityListReducer = (state = {
  balancesheetliabilitiesandequitylist: []
}, action) => {
  switch (action.type) {
    case BALANCE_SHEET_LIABILITIES_EQUITY_REQUEST:
      return {
        balanceSheetLiabilitiesAndEquityLoading: true, balancesheetliabilitiesandequitylist: []
      }
      case BALANCE_SHEET_LIABILITIES_EQUITY_SUCCESS:
        return {
          balanceSheetLiabilitiesAndEquityLoading: false, balancesheetliabilitiesandequitylist: action.payload,
        }
        case BALANCE_SHEET_LIABILITIES_EQUITY_FAIL:
          return {
              balanceSheetLiabilitiesAndEquityLoading: false, error: action.payload
          }
          default:
            return state
  }
}

export const balanceSheetLiabilitiesAndEquityMultiListReducer = (state = {
  balancesheetliabilitiesandequitymultilist: []
}, action) => {
  switch (action.type) {
    case BALANCE_SHEET_LIABILITIES_EQUITY_REQUEST:
      return {
        balanceSheetLiabilitiesAndEquityMultiLoading: true, balancesheetliabilitiesandequitymultilist: []
      }
      case BALANCE_SHEET_LIABILITIES_EQUITY_SUCCESS:
        return {
          balanceSheetLiabilitiesAndEquityMultiLoading: false, balancesheetliabilitiesandequitymultilist: action.payload,
        }
        case BALANCE_SHEET_LIABILITIES_EQUITY_FAIL:
          return {
            balanceSheetLiabilitiesAndEquityMultiLoading: false, error: action.payload
          }
          default:
            return state
  }
}

export const balanceSheetLiabilitiesAndEquityAllMultiListReducer = (state = {
  balancesheetliabilitiesandequityallmultilist: []
}, action) => {
  switch (action.type) {
    case BALANCE_SHEET_LIABILITIES_EQUITY_REQUEST:
      return {
        balanceSheetLiabilitiesAndEquityAllMultiLoading: true, balancesheetliabilitiesandequityallmultilist: []
      }
      case BALANCE_SHEET_LIABILITIES_EQUITY_SUCCESS:
        return {
          balanceSheetLiabilitiesAndEquityAllMultiLoading: false, balancesheetliabilitiesandequityallmultilist: action.payload,
        }
        case BALANCE_SHEET_LIABILITIES_EQUITY_FAIL:
          return {
            balanceSheetLiabilitiesAndEquityAllMultiLoading: false, error: action.payload
          }
          default:
            return state
  }
}

export const balanceSheetLiabilitiesAndEquityYearListReducer = (state = {
  balancesheetliabilitiesandequityyearlist: []
}, action) => {
  switch (action.type) {
    case BALANCE_SHEET_LIABILITIES_EQUITY_REQUEST:
      return {
        balanceSheetLiabilitiesAndEquityYearLoading: true, balancesheetliabilitiesandequityyearlist: []
      }
      case BALANCE_SHEET_LIABILITIES_EQUITY_SUCCESS:
        return {
          balanceSheetLiabilitiesAndEquityYearLoading: false, balancesheetliabilitiesandequityyearlist: action.payload,
        }
        case BALANCE_SHEET_LIABILITIES_EQUITY_FAIL:
          return {
            balanceSheetLiabilitiesAndEquityYearLoading: false, error: action.payload
          }
          default:
            return state
  }
}

export const balanceSheetLiabilitiesAndEquityYearMultiListReducer = (state = {
  balancesheetliabilitiesandequityyearmultilist: []
}, action) => {
  switch (action.type) {
    case BALANCE_SHEET_LIABILITIES_EQUITY_REQUEST:
      return {
        balanceSheetLiabilitiesAndEquityYearMultiLoading: true, balancesheetliabilitiesandequityyearmultilist: []
      }
      case BALANCE_SHEET_LIABILITIES_EQUITY_SUCCESS:
        return {
          balanceSheetLiabilitiesAndEquityYearMultiLoading: false, balancesheetliabilitiesandequityyearmultilist: action.payload,
        }
        case BALANCE_SHEET_LIABILITIES_EQUITY_FAIL:
          return {
            balanceSheetLiabilitiesAndEquityYearMultiLoading: false, error: action.payload
          }
          default:
            return state
  }
}

export const balanceSheetLiabilitiesAndEquityNaiceListReducer = (state = {
  balancesheetliabilitiesandequitynaicelist: []
}, action) => {
  switch (action.type) {
    case BALANCE_SHEET_LIABILITIES_EQUITY_REQUEST:
      return {
        balanceSheetLiabilitiesAndEquityNaiceLoading: true, balancesheetliabilitiesandequitynaicelist: []
      }
      case BALANCE_SHEET_LIABILITIES_EQUITY_SUCCESS:
        return {
          balanceSheetLiabilitiesAndEquityNaiceLoading: false, balancesheetliabilitiesandequitynaicelist: action.payload,
        }
        case BALANCE_SHEET_LIABILITIES_EQUITY_FAIL:
          return {
            balanceSheetLiabilitiesAndEquityNaiceLoading: false, error: action.payload
          }
          default:
            return state
  }
}