import React, { Suspense, lazy, useEffect, useState } from "react";
// import Home from "./components/Home/index.jsx";
import "../src/assests/css/style.css";
import "./Dealhub/Dealhub.css";
import "../src/assests/css/responsive.css";
// import ContactPage from "./components/ContactUs/index.jsx";
import { Routes, Route, Link } from "react-router-dom";
import { routes, PremiumRoutes } from "./routes";
import Loader from "./common/Loader";
import PremiumCheck from "./components/BenchmarkBusinessPremium/PremiumCheck";
// import SignInHome from "./components/SignIn/index.jsx";
// import ProfileSec from "./components/ProfilePages/index.jsx";
// import BusinessInfoPage from "./components/BuinessInformation/index.jsx";
// import BenchmarkPremiumPage from "./components/BenchmarkBusinessPremium/index.jsx";
// import BenchmarkBusinessPage from "./components/BenchmarkBusiness/index.jsx";
// import Glossary from "./components/SubComponent/BasicChart/Glossary/Glossary.jsx";
// import IncomeStatement from "./components/SubComponent/BasicChart/IncomeStatement/IncomeStatement.jsx";
// import BalanceSheet from "./components/SubComponent/BasicChart/BalanceSheet/BalanceSheet.jsx";
// import Profitability from "./components/SubComponent/BasicChart/Profitability/Profitability.jsx";
// import CostOfSale from "./components/SubComponent/BasicChart/CostOfSale/CostOfSale.jsx";
// import OperatingExpenses from "./components/SubComponent/BasicChart/OperatingExpenses/OperatingExpenses.jsx";
// import ProfitabilityTwo from "./components/SubComponent/BasicChart/ProfitabilityTwo/ProfitabilityTwo.jsx";
// import BalanceSheetAnalysis from "./components/SubComponent/BasicChart/BalanceSheetAnalysis/BalanceSheetAnalysis.jsx";
// import LeverageDebtOfEquaty from "./components/SubComponent/BasicChart/LeverageDebtOfEquity/LeverageDebtOfEquaty.jsx";
// import LeverageDebtCApitalization from "./components/SubComponent/BasicChart/LeverageDebtOfCapitalization/LeverageDebtCApitalization.jsx";
// import Liquidity from "./components/SubComponent/BasicChart/Liquidity/Liquidity.jsx";
// import Efficiency from "./components/SubComponent/BasicChart/Efficiency/Efficiency.jsx";
// import UpperEfficiency from "./components/SubComponent/BasicChart/UpperEfficeincy/UpperEfficiency.jsx";
// import BusinessForm from "./components/BuinessInformation/BusinessForm.jsx";
// import SignUpPage from "./components/SingnUp/signUp.jsx";
// import BenchmarkYourBusinessFilter from "./components/SubComponent/BasicChart/BenchMarkBusinessFilter/BenchmarkBusinessFilterChart";
// import BenchmarkBusinessIframe from "./components/BenchmarkBusiness/BenchmarkBusinessIframe.jsx";
// import BusinessEditPage from "./components/BuinessInformation/BusinessEditPage.jsx";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);
  return loading ? (
    <Loader />
  ) : (
    <div className="App">
      <Routes>
        {/* <Route path="/" element={<Home />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="/signinpage" element={<SignInHome />} />
        <Route path="/profile" element={<ProfileSec />} />
        <Route path="/businessinfo" element={<BusinessInfoPage />} />
        <Route
          path="/businessinfo/:businessId"
          element={<BusinessEditPage />}
        />
        <Route
          path="/balancesheet/edit/:businessId"
          element={<BalanceSheetEditPage />}
        />
        <Route
          path="/benchmarkbusiness"
          element={<BenchmarkBusinessIframe />}
        />
        <Route path="/businessform" element={<BusinessForm />} />
        <Route path="/tsform" element={<DealhubForm />} />
        <Route path="/signuppage" element={<SignUpPage />} />
        <Route path="/incomestatement" element={<IncomeStatement />} />
        <Route
          path="/incomestatement/edit/:businessId"
          element={<IncomeStatementEditPage />}
        />
        <Route path="/balancesheet" element={<BalanceSheet />} />
        <Route path="/profitability" element={<Profitability />} />
        <Route path="/costOfSale" element={<CostOfSale />} />
        <Route path="/operatingExpenses" element={<OperatingExpenses />} />
        <Route path="/profitabilityTwo" element={<ProfitabilityTwo />} />
        <Route
          path="/balanceSheetAnalysis"
          element={<BalanceSheetAnalysis />}
        />
        <Route
          path="/leverageDebtOfEquaty"
          element={<LeverageDebtOfEquaty />}
        />
        <Route
          path="/leverageDebtCApitalization"
          element={<LeverageDebtCApitalization />}
        />
        <Route path="/liquidity" element={<Liquidity />} />
        <Route path="/efficiency" element={<Efficiency />} />
        <Route path="/upperEfficiency" element={<UpperEfficiency />} />
        <Route path="/glossary" element={<Glossary />} />


        <Route
          path="/benchmarkpremiumList"
          element={
            <PremiumCheck>
              <PlanList />
            </PremiumCheck>
          }
        />
        <Route
          path="/benchmarkpremium"
          element={
            <PremiumCheck>
              <BenchmarkBusinessPremiumIframe />
            </PremiumCheck>
          }
        />
        <Route
          path="/incomestatementpremium"
          element={<IncomeStatementPremium />}
        />
        <Route path="/balancesheetpremium" element={<BalanceSheetPremium />} />
        <Route
          path="/profitabilitypremium"
          element={<ProfitabilityPremium />}
        />
        <Route path="/costofsalespremium" element={<CostofSalesPremium />} />
        <Route
          path="/operatingexpensespremiumone"
          element={<OperatingExpensesPremiumOne />}
        />
        <Route
          path="/operatingexpensespremiumTwo"
          element={<OperatingExpensesPremiumTwo />}
        />
        <Route
          path="/profibilitypremiumtwo"
          element={<ProfibilityPremiumTwo />}
        />
        <Route
          path="/balancesheetanalysispremium"
          element={<BalanceSheetAnalysisPremium />}
        />
        <Route
          path="/leveragedebtofequitypremium"
          element={<LeverageDebtOfEquityPremium />}
        />
        <Route
          path="/leveragedebtofcapitalizationpremium"
          element={<LeverageDebtOfCapitalizationPremium />}
        />
        <Route path="/liquiditypremium" element={<LiquidityPremium />} />
        <Route path="/efficiencypremium" element={<EfficiencyPremium />} />
        <Route path="/glossarypremium" element={<GlossaryPremium />} />
        <Route path="/success" element={<Success />} />
        <Route path="/cancel" element={<Cancel />} />
        <Route path="/faq" element={<FaqPage />} />
        <Route path="/termCondition" element={<TermCondition />} />
        <Route path="/privacyPolicy" element={<PrivacyPolicy />} /> */}
        {/* <Route
                    path="/addMyDeal"
                    element={
                      <Suspense fallback={<ButtonLoader />}>
                        {" "}
                        <AddMyDeal />
                      </Suspense>
                    }
                  /> */}

        {/* dealhub routes start */}
        {routes?.map((route, i) => {
          const { path, component: Component } = route;
          return (
            <Route
              path={path}
              element={
                <Suspense fallback={<Loader />}>{<Component />}</Suspense>
              }
            />
          );
        })}
        {PremiumRoutes?.map((list) => {
          const { path, component: Component } = list;
          return (
            <Route
              path={path}
              element={
                <Suspense fallback={<Loader />}>
                  {
                    <PremiumCheck>
                      <Component />
                    </PremiumCheck>
                  }
                </Suspense>
              }
            />
          );
        })}
      </Routes>
    </div>
  );
}

export default App;
