import {
  createStore,
  combineReducers,
  applyMiddleware
} from "redux";
import thunk from "redux-thunk";
import {
  composeWithDevTools
} from "redux-devtools-extension";
import {
  userDetailsReducer,
  userLoginReducer,
  // userProfileReducer
} from "./redux/reducers/userReducers";
import {
  multiCompanyNumberReducer,
  allMultiCompanyNumberReducer,
  yearMultiCompanyNumberReducer,
  naicsCompanyNumberReducer,
  businessListReducer,
  businessTypeCompanyNumberReducer,
  locationCompanyNumberReducer,
  yearCompanyNumberReducer,
  revenueCompanyNumberReducer,
  filterValueReducer,
  GeoCodeReducer
} from "./redux/reducers/BusinessReducer"
import {
  incomeStatementCostOfSaleAllMultiListReducer,
  incomeStatementCostOfSaleListReducer,
  incomeStatementCostOfSaleMultiListReducer,
  incomeStatementCostOfSaleNaiceListReducer,
  incomeStatementCostOfSaleYearListReducer,
  incomeStatementCostOfSaleYearMultiListReducer,
  incomeStatementOperatingExpensesAllMultiListReducer,
  incomeStatementOperatingExpensesListReducer,
  incomeStatementOperatingExpensesMultiListReducer,
  incomeStatementOperatingExpensesNaiceListReducer,
  incomeStatementOperatingExpensesYearListReducer,
  incomeStatementOperatingExpensesYearMultiListReducer,
  incomeStatementQuartileRevenueAllMultiListReducer,
  incomeStatementQuartileRevenueListReducer,
  incomeStatementQuartileRevenueMultiListReducer,
  incomeStatementQuartileRevenueNaiceListReducer,
  incomeStatementQuartileRevenueYearListReducer,
  incomeStatementQuartileRevenueYearMultiListReducer,
  incomeStatementRevenueAllMultiListReducer,
  incomeStatementRevenueListReducer,
  incomeStatementRevenueMultiListReducer,
  incomeStatementRevenueNaiceListReducer,
  incomeStatementRevenueYearListReducer,
  incomeStatementRevenueYearMultiListReducer
} from "./redux/reducers/IncomeStatementReducers"
import {
  balanceSheetAssetsListReducer,
  balanceSheetAssetsMultiListReducer,
  balanceSheetAssetsAllMultiListReducer,
  balanceSheetAssetsYearListReducer,
  balanceSheetAssetsYearMultiListReducer,
  balanceSheetAssetsNaiceListReducer,
  balanceSheetLiabilitiesAndEquityListReducer,
  balanceSheetLiabilitiesAndEquityMultiListReducer,
  balanceSheetLiabilitiesAndEquityAllMultiListReducer,
  balanceSheetLiabilitiesAndEquityYearListReducer,
  balanceSheetLiabilitiesAndEquityYearMultiListReducer,
  balanceSheetLiabilitiesAndEquityNaiceListReducer
} from "./redux/reducers/BalanceSheetReducer";
import { bizNameListReducer, getbizNamDeataListReducer, myBizNameListReducer } from "./redux/reducers/PremiumBenchMarkMyBusiness";
import { myBizIncomeSheetListReducer } from "./redux/reducers/MyBizIncomeStatement";
import { myBizBalanceSheetListReducer } from "./redux/reducers/MyBizBalanceSheet";
import { subscriptionPlanReducer } from "./redux/reducers/SubscriptionReducer";

const reducer = combineReducers({
  userLogin: userLoginReducer,
  userDetails: userDetailsReducer,
  benchmarkFilter: businessListReducer,
  // userProfile:userProfileReducer,

  businessTypeCompanyNumberData: businessTypeCompanyNumberReducer,
  revenueCompanyNumberData: revenueCompanyNumberReducer,
  locationCompanyNumberData: locationCompanyNumberReducer,
  yearCompanyNumberData: yearCompanyNumberReducer,
  naicsCompanyNumberData: naicsCompanyNumberReducer,
  multiCompanyNumberData: multiCompanyNumberReducer,
  allMultiCompanyNumberData: allMultiCompanyNumberReducer,
  yearMultiCompanyNumberData: yearMultiCompanyNumberReducer,

  filterValueData: filterValueReducer,

  GeoCodeData: GeoCodeReducer,

  incomeStatementQuartileRevenueListData: incomeStatementQuartileRevenueListReducer,
  incomeStatementQuartileRevenueYearMultiListData: incomeStatementQuartileRevenueYearMultiListReducer,
  incomeStatementQuartileRevenueYearListData: incomeStatementQuartileRevenueYearListReducer,
  incomeStatementQuartileRevenueNaiceListData: incomeStatementQuartileRevenueNaiceListReducer,
  incomeStatementQuartileRevenueMultiListData: incomeStatementQuartileRevenueMultiListReducer,
  incomeStatementQuartileRevenueAllMultiListData: incomeStatementQuartileRevenueAllMultiListReducer,

  incomeStatementRevenueListData: incomeStatementRevenueListReducer,
  incomeStatementRevenueYearMultiListData: incomeStatementRevenueYearMultiListReducer,
  incomeStatementRevenueYearListData: incomeStatementRevenueYearListReducer,
  incomeStatementRevenueNaiceListData: incomeStatementRevenueNaiceListReducer,
  incomeStatementRevenueMultiListData: incomeStatementRevenueMultiListReducer,
  incomeStatementRevenueAllMultiListData: incomeStatementRevenueAllMultiListReducer,

  incomeStatementCostOfSaleListData: incomeStatementCostOfSaleListReducer,
  incomeStatementCostOfSaleYearMultiListData: incomeStatementCostOfSaleYearMultiListReducer,
  incomeStatementCostOfSaleYearListData: incomeStatementCostOfSaleYearListReducer,
  incomeStatementCostOfSaleNaiceListData: incomeStatementCostOfSaleNaiceListReducer,
  incomeStatementCostOfSaleMultiListData: incomeStatementCostOfSaleMultiListReducer,
  incomeStatementCostOfSaleAllMultiListData: incomeStatementCostOfSaleAllMultiListReducer,

  incomeStatementOperatingExpensesListData: incomeStatementOperatingExpensesListReducer,
  incomeStatementOperatingExpensesYearMultiListData: incomeStatementOperatingExpensesYearMultiListReducer,
  incomeStatementOperatingExpensesYearListData: incomeStatementOperatingExpensesYearListReducer,
  incomeStatementOperatingExpensesNaiceListData: incomeStatementOperatingExpensesNaiceListReducer,
  incomeStatementOperatingExpensesMultiListData: incomeStatementOperatingExpensesMultiListReducer,
  incomeStatementOperatingExpensesAllMultiListData: incomeStatementOperatingExpensesAllMultiListReducer,

  balanceSheetAssetsListData: balanceSheetAssetsListReducer,
  balanceSheetAssetsMultiListData: balanceSheetAssetsMultiListReducer,
  balanceSheetAssetsAllMultiListData: balanceSheetAssetsAllMultiListReducer,
  balanceSheetAssetsYearListData: balanceSheetAssetsYearListReducer,
  balanceSheetAssetsYearMultiListData: balanceSheetAssetsYearMultiListReducer,
  balanceSheetAssetsNaiceListData: balanceSheetAssetsNaiceListReducer,

  balanceSheetLiabilitiesAndEquityListData: balanceSheetLiabilitiesAndEquityListReducer,
  balanceSheetLiabilitiesAndEquityMultiListData: balanceSheetLiabilitiesAndEquityMultiListReducer,
  balanceSheetLiabilitiesAndEquityAllMultiListData: balanceSheetLiabilitiesAndEquityAllMultiListReducer,
  balanceSheetLiabilitiesAndEquityYearListData: balanceSheetLiabilitiesAndEquityYearListReducer,
  balanceSheetLiabilitiesAndEquityYearMultiListData: balanceSheetLiabilitiesAndEquityYearMultiListReducer,
  balanceSheetLiabilitiesAndEquityNaiceListData: balanceSheetLiabilitiesAndEquityNaiceListReducer,

  biznamelist:bizNameListReducer,
  getbiznamelist:getbizNamDeataListReducer,
  bizNamelist:myBizNameListReducer,

  subscriptionPlanListData:subscriptionPlanReducer,

  myBizIncomeSheetList:myBizIncomeSheetListReducer,
  myBizBalanceSheetList:myBizBalanceSheetListReducer

});

const userInfoFromStorage = localStorage.getItem("loginData") ?
  JSON.parse(localStorage.getItem("loginData")) :
  null;

const initialState = {
  userLogin: {
    loginUser: userInfoFromStorage
  },
};
const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;