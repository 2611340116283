import {
  BUSINESS_LIST_FAIL,
  BUSINESS_LIST_REQUEST,
  BUSINESS_LIST_SUCCESS,
  BUSINESS_TYPE_COMPANY_NUMBER_LIST_REQUEST,
  BUSINESS_TYPE_COMPANY_NUMBER_LIST_SUCCESS,
  BUSINESS_TYPE_COMPANY_NUMBER_LIST_FAIL,
  LOCATION_COMPANY_NUMBER_LIST_REQUEST,
  LOCATION_COMPANY_NUMBER_LIST_SUCCESS,
  LOCATION_COMPANY_NUMBER_LIST_FAIL,
  YEAR_COMPANY_NUMBER_LIST_REQUEST,
  YEAR_COMPANY_NUMBER_LIST_SUCCESS,
  YEAR_COMPANY_NUMBER_LIST_FAIL,
  REVENUE_COMPANY_NUMBER_LIST_REQUEST,
  REVENUE_COMPANY_NUMBER_LIST_SUCCESS,
  REVENUE_COMPANY_NUMBER_LIST_FAIL,
  NAICS_COMPANY_NUMBER_LIST_REQUEST,
  NAICS_COMPANY_NUMBER_LIST_SUCCESS,
  NAICS_COMPANY_NUMBER_LIST_FAIL,
  MULTI_COMPANY_NUMBER_LIST_REQUEST,
  MULTI_COMPANY_NUMBER_LIST_SUCCESS,
  MULTI_COMPANY_NUMBER_LIST_FAIL,
  ALL_MULTI_COMPANY_NUMBER_LIST_REQUEST,
  ALL_MULTI_COMPANY_NUMBER_LIST_SUCCESS,
  ALL_MULTI_COMPANY_NUMBER_LIST_FAIL,
  YEAR_MULTI_COMPANY_NUMBER_LIST_REQUEST,
  YEAR_MULTI_COMPANY_NUMBER_LIST_SUCCESS,
  YEAR_MULTI_COMPANY_NUMBER_LIST_FAIL,
  FILTER_VALUE_LIST_REQUEST,
  FILTER_VALUE_LIST_SUCCESS,
  FILTER_VALUE_LIST_FAIL,
  GEO_CODE_FILTER_VALUE_LIST_REQUEST,
  GEO_CODE_FILTER_VALUE_LIST_SUCCESS,
  GEO_CODE_FILTER_VALUE_LIST_FAIL
} from "../constants/BusinessConstants"

export const businessListReducer = (state = {
  businesslist: []
}, action) => {
  switch (action.type) {
    case BUSINESS_LIST_REQUEST:
      return {
        loading: true, businesslist: []
      }
      case BUSINESS_LIST_SUCCESS:
        return {
          loading: false,
            businesslist: action.payload,
        }
        case BUSINESS_LIST_FAIL:
          return {
            loading: false, error: action.payload
          }
          default:
            return state
  }
}

export const revenueCompanyNumberReducer = (state = {
  revenuecompanynumber: []
}, action) => {
  switch (action.type) {
    case REVENUE_COMPANY_NUMBER_LIST_REQUEST:
      return {
        revenueLoading: true, revenuecompanynumber: []
      }
      case REVENUE_COMPANY_NUMBER_LIST_SUCCESS:
        return {
          revenueLoading: false,
            revenuecompanynumber: action.payload,
        }
        case REVENUE_COMPANY_NUMBER_LIST_FAIL:
          return {
            revenueLoading: false, error: action.payload
          }
          default:
            return state
  }
}

export const businessTypeCompanyNumberReducer = (state = {
  businesstypecompanynumber: []
}, action) => {
  switch (action.type) {
    case BUSINESS_TYPE_COMPANY_NUMBER_LIST_REQUEST:
      return {
        businessTypeLoading: true, businesstypecompanynumber: []
      }
      case BUSINESS_TYPE_COMPANY_NUMBER_LIST_SUCCESS:
        return {
          businessTypeLoading: false,
            businesstypecompanynumber: action.payload,
        }
        case BUSINESS_TYPE_COMPANY_NUMBER_LIST_FAIL:
          return {
            businessTypeLoading: false, error: action.payload
          }
          default:
            return state
  }
}

export const locationCompanyNumberReducer = (state = {
  locationcompanynumber: []
}, action) => {
  switch (action.type) {
    case LOCATION_COMPANY_NUMBER_LIST_REQUEST:
      return {
        locationLoading: true, locationcompanynumber: []
      }
      case LOCATION_COMPANY_NUMBER_LIST_SUCCESS:
        return {
          locationLoading: false,
            locationcompanynumber: action.payload,
        }
        case LOCATION_COMPANY_NUMBER_LIST_FAIL:
          return {
            locationLoading: false, error: action.payload
          }
          default:
            return state
  }
}

export const yearCompanyNumberReducer = (state = {
  yearcompanynumber: []
}, action) => {
  switch (action.type) {
    case YEAR_COMPANY_NUMBER_LIST_REQUEST:
      return {
        yearLoading: true, yearcompanynumber: []
      }
      case YEAR_COMPANY_NUMBER_LIST_SUCCESS:
        return {
          yearLoading: false,
            yearcompanynumber: action.payload,
        }
        case YEAR_COMPANY_NUMBER_LIST_FAIL:
          return {
            yearLoading: false, error: action.payload
          }
          default:
            return state
  }
}

export const naicsCompanyNumberReducer = (state = {
  naicscompanynumber: []
}, action) => {
  switch (action.type) {
    case NAICS_COMPANY_NUMBER_LIST_REQUEST:
      return {
        naicsLoading: true, naicscompanynumber: []
      }
      case NAICS_COMPANY_NUMBER_LIST_SUCCESS:
        return {
          naicsLoading: false,
            naicscompanynumber: action.payload,
        }
        case NAICS_COMPANY_NUMBER_LIST_FAIL:
          return {
            naicsLoading: false, error: action.payload
          }
          default:
            return state
  }
}

export const multiCompanyNumberReducer = (state = {
  multicompanynumber: []
}, action) => {
  switch (action.type) {
    case MULTI_COMPANY_NUMBER_LIST_REQUEST:
      return {
        multiLoading: true, multicompanynumber: []
      }
      case MULTI_COMPANY_NUMBER_LIST_SUCCESS:
        return {
          multiLoading: false,
            multicompanynumber: action.payload,
        }
        case MULTI_COMPANY_NUMBER_LIST_FAIL:
          return {
            multiLoading: false, error: action.payload
          }
          default:
            return state
  }
}

export const allMultiCompanyNumberReducer = (state = {
  allmulticompanynumber: []
}, action) => {
  switch (action.type) {
    case ALL_MULTI_COMPANY_NUMBER_LIST_REQUEST:
      return {
        allMultiLoading: true, allmulticompanynumber: []
      }
      case ALL_MULTI_COMPANY_NUMBER_LIST_SUCCESS:
        return {
          allMultiLoading: false,
            allmulticompanynumber: action.payload,
        }
        case ALL_MULTI_COMPANY_NUMBER_LIST_FAIL:
          return {
            allMultiLoading: false, error: action.payload
          }
          default:
            return state
  }
}

export const yearMultiCompanyNumberReducer = (state = {
  yearmulticompanynumber: []
}, action) => {
  switch (action.type) {
    case YEAR_MULTI_COMPANY_NUMBER_LIST_REQUEST:
      return {
        yearMultiLoading: true, yearmulticompanynumber: []
      }
      case YEAR_MULTI_COMPANY_NUMBER_LIST_SUCCESS:
        return {
          yearMultiLoading: false,
            yearmulticompanynumber: action.payload,
        }
        case YEAR_MULTI_COMPANY_NUMBER_LIST_FAIL:
          return {
            yearMultiLoading: false, error: action.payload
          }
          default:
            return state
  }
}

export const filterValueReducer = (state = {
  filtervalue: []
}, action) => {
  switch (action.type) {
    case FILTER_VALUE_LIST_REQUEST:
      return {
        filtervalue: []
      }
      case FILTER_VALUE_LIST_SUCCESS:
        return {
          
          filtervalue: action.payload,
        }
        case FILTER_VALUE_LIST_FAIL:
          return {
            error: action.payload
          }
          default:
            return state
  }
}

export const GeoCodeReducer = (state = {
  geocode: []
}, action) => {
  switch (action.type) {
    case GEO_CODE_FILTER_VALUE_LIST_REQUEST:
      return {
        geocode: []
      }
      case GEO_CODE_FILTER_VALUE_LIST_SUCCESS:
        return {
          
          geocode: action.payload,
        }
        case GEO_CODE_FILTER_VALUE_LIST_FAIL:
          return {
            error: action.payload
          }
          default:
            return state
  }
}