import {
    SUBSCRIPTION_PLAN_REQUEST,
    SUBSCRIPTION_PLAN_SUCCESS,
    SUBSCRIPTION_PLAN_FAIL
} from "../constants/SubscriptionContants.js"

const initialState = {
    loading: false,
    currentUser: null,
    error: null,
}

export const subscriptionPlanReducer = (state = {
    subscriptionplanlis:[]
}, action) => {
    switch (action.type) {
        case SUBSCRIPTION_PLAN_REQUEST:
            return {
                planListLoading: true, subscriptionplanlist:[]
            }

            case SUBSCRIPTION_PLAN_SUCCESS:
                return {
                    planListLoading: false,
                        subscriptionplanlist: action.payload,
                }

                case SUBSCRIPTION_PLAN_FAIL:
                    return {
                        planListLoading: false, error: action.payload
                    }
                    default:
                        return state
    }
}