export const BIZ_NAME_REQUEST = "BIZ_NAME_REQUEST";
export const BIZ_NAME_SUCCESS = "BIZ_NAME__SUCCESS";
export const BIZ_NAME_FAIL = "BIZ_NAME__FAIL";

export const BIZ_NAME_DATA_REQUEST = "BIZ_NAME_DATA_REQUEST";
export const BIZ_NAME_DATA_SUCCESS = "BIZ_NAME_DATA_SUCCESS";
export const BIZ_NAME_DATA_FAIL = "BIZ_NAME_DATA_FAIL";

export const BIZNAME_VALUE_LIST_REQUEST = " BIZNAME_VALUE_LIST_REQUEST";
export const BIZNAME_VALUE_LIST_SUCCESS = "BIZNAME_VALUE_LIST_SUCCESS";
export const BIZNAME_VALUE_LIST_FAIL = "BIZNAME_VALUE_LIST_FAIL";