import { 
    BIZ_BALANCE_SHEET_REQUEST,
    BIZ_BALANCE_SHEET_INCOME_SUCCESS,
    BIZ_BALANCE_SHEET_INCOME_FAIL   
  } from "../constants/MyBizBalanceSheet";

  export const myBizBalanceSheetListReducer = (state = {
    mybizbalancesheetdatalist: []
  }, action) => {
    switch (action.type) {
      case BIZ_BALANCE_SHEET_REQUEST:
        return {
            mybizbalancesheetloading: true, mybizbalancesheetdatalist: []
        }
        case BIZ_BALANCE_SHEET_INCOME_SUCCESS:
          return {
            mybizbalancesheetloading: false,
            mybizbalancesheetdatalist: action.payload,
          }
          case BIZ_BALANCE_SHEET_INCOME_FAIL:
            return {
                mybizbalancesheetloading: false, error: action.payload
            }
            default:
              return state
    }
  }