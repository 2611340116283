import {
  INCOME_STATEMENT_QUARTILE_REVENUE_FAIL,
  INCOME_STATEMENT_QUARTILE_REVENUE_REQUEST,
  INCOME_STATEMENT_QUARTILE_REVENUE_SUCCESS,
  INCOME_STATEMENT_REVENUE_REQUEST,
  INCOME_STATEMENT_REVENUE_SUCCESS,
  INCOME_STATEMENT_REVENUE_FAIL,
  INCOME_STATEMENT_COSTOFSALE_REQUEST,
  INCOME_STATEMENT_COSTOFSALE_SUCCESS,
  INCOME_STATEMENT_COSTOFSALE_FAIL,
  INCOME_STATEMENT_OPERATINGEXPENSES_REQUEST,
  INCOME_STATEMENT_OPERATINGEXPENSES_SUCCESS,
  INCOME_STATEMENT_OPERATINGEXPENSESE_FAIL,
} from "../constants/IncomeStatementConstants"

// QUARTILE

export const incomeStatementQuartileRevenueListReducer = (state = {
  incomequartilerevenuelist: []
}, action) => {
  switch (action.type) {
    case INCOME_STATEMENT_QUARTILE_REVENUE_REQUEST:
      return {
        quartileRevenueLoading: true, incomequartilerevenuelist: []
      }
      case INCOME_STATEMENT_QUARTILE_REVENUE_SUCCESS:
        return {
          quartileRevenueLoading: false, incomequartilerevenuelist: action.payload,
        }
        case INCOME_STATEMENT_QUARTILE_REVENUE_FAIL:
          return {
            quartileRevenueLoading: false, error: action.payload
          }
          default:
            return state
  }
}

export const incomeStatementQuartileRevenueYearMultiListReducer = (state = {
  incomequartilerevenueyearmultilist: []
}, action) => {
  switch (action.type) {
    case INCOME_STATEMENT_QUARTILE_REVENUE_REQUEST:
      return {
        quartileRevenueYearMultiLoading: true, incomequartilerevenueyearmultilist: []
      }
      case INCOME_STATEMENT_QUARTILE_REVENUE_SUCCESS:
        return {
          quartileRevenueYearMultiLoading: false, incomequartilerevenueyearmultilist: action.payload,
        }
        case INCOME_STATEMENT_QUARTILE_REVENUE_FAIL:
          return {
            quartileRevenueYearMultiLoading: false, error: action.payload
          }
          default:
            return state
  }
}

export const incomeStatementQuartileRevenueYearListReducer = (state = {
  incomequartilerevenueyearlist: []
}, action) => {
  switch (action.type) {
    case INCOME_STATEMENT_QUARTILE_REVENUE_REQUEST:
      return {
        quartileRevenueYearLoading: true, incomequartilerevenueyearlist: []
      }
      case INCOME_STATEMENT_QUARTILE_REVENUE_SUCCESS:
        return {
          quartileRevenueYearLoading: false, incomequartilerevenueyearlist: action.payload,
        }
        case INCOME_STATEMENT_QUARTILE_REVENUE_FAIL:
          return {
            quartileRevenueYearLoading: false, error: action.payload
          }
          default:
            return state
  }
}

export const incomeStatementQuartileRevenueNaiceListReducer = (state = {
  incomequartilerevenuenaicelist: []
}, action) => {
  switch (action.type) {
    case INCOME_STATEMENT_QUARTILE_REVENUE_REQUEST:
      return {
        quartileRevenueNaiceLoading: true, incomequartilerevenuenaicelist: []
      }
      case INCOME_STATEMENT_QUARTILE_REVENUE_SUCCESS:
        return {
          quartileRevenueNaiceLoading: false, incomequartilerevenuenaicelist: action.payload,
        }
        case INCOME_STATEMENT_QUARTILE_REVENUE_FAIL:
          return {
            quartileRevenueNaiceLoading: false, error: action.payload
          }
          default:
            return state
  }
}

export const incomeStatementQuartileRevenueMultiListReducer = (state = {
  incomequartilerevenuemultilist: []
}, action) => {
  switch (action.type) {
    case INCOME_STATEMENT_QUARTILE_REVENUE_REQUEST:
      return {
        quartileRevenueMultiLoading: true, incomequartilerevenuemultilist: []
      }
      case INCOME_STATEMENT_QUARTILE_REVENUE_SUCCESS:
        return {
          quartileRevenueMultiLoading: false, incomequartilerevenuemultilist: action.payload,
        }
        case INCOME_STATEMENT_QUARTILE_REVENUE_FAIL:
          return {
            quartileRevenueMultiLoading: false, error: action.payload
          }
          default:
            return state
  }
}

export const incomeStatementQuartileRevenueAllMultiListReducer = (state = {
  incomequartilerevenueallmultilist: []
}, action) => {
  switch (action.type) {
    case INCOME_STATEMENT_QUARTILE_REVENUE_REQUEST:
      return {
        quartileRevenueAllMultiLoading: true, incomequartilerevenueallmultilist: []
      }
      case INCOME_STATEMENT_QUARTILE_REVENUE_SUCCESS:
        return {
          quartileRevenueAllMultiLoading: false, incomequartilerevenueallmultilist: action.payload,
        }
        case INCOME_STATEMENT_QUARTILE_REVENUE_FAIL:
          return {
            quartileRevenueAllMultiLoading: false, error: action.payload
          }
          default:
            return state
  }
}

// REVENUE

export const incomeStatementRevenueListReducer = (state = {
  incomerevenuelist: []
}, action) => {
  switch (action.type) {
    case INCOME_STATEMENT_REVENUE_REQUEST:
      return {
        revenueListLoading: true, incomerevenuelist: []
      }
      case INCOME_STATEMENT_REVENUE_SUCCESS:
        return {
          revenueListLoading: false, incomerevenuelist: action.payload,
        }
        case INCOME_STATEMENT_REVENUE_FAIL:
          return {
            revenueListLoading: false, error: action.payload
          }
          default:
            return state
  }
}

export const incomeStatementRevenueYearMultiListReducer = (state = {
  incomerevenueyearmultilist: []
}, action) => {
  switch (action.type) {
    case INCOME_STATEMENT_REVENUE_REQUEST:
      return {
        revenueListYearMultiLoading: true, incomerevenueyearmultilist: []
      }
      case INCOME_STATEMENT_REVENUE_SUCCESS:
        return {
          revenueListYearMultiLoading: false, incomerevenueyearmultilist: action.payload,
        }
        case INCOME_STATEMENT_REVENUE_FAIL:
          return {
            revenueListYearMultiLoading: false, error: action.payload
          }
          default:
            return state
  }
}

export const incomeStatementRevenueYearListReducer = (state = {
  incomerevenueyearlist: []
}, action) => {
  switch (action.type) {
    case INCOME_STATEMENT_REVENUE_REQUEST:
      return {
        revenueListYearLoading: true, incomerevenueyearlist: []
      }
      case INCOME_STATEMENT_REVENUE_SUCCESS:
        return {
          revenueListYearLoading: false, incomerevenueyearlist: action.payload,
        }
        case INCOME_STATEMENT_REVENUE_FAIL:
          return {
            revenueListYearLoading: false, error: action.payload
          }
          default:
            return state
  }
}

export const incomeStatementRevenueNaiceListReducer = (state = {
  incomerevenuenaicelist: []
}, action) => {
  switch (action.type) {
    case INCOME_STATEMENT_REVENUE_REQUEST:
      return {
        revenueListNaiceLoading: true, incomerevenuenaicelist: []
      }
      case INCOME_STATEMENT_REVENUE_SUCCESS:
        return {
          revenueListNaiceLoading: false, incomerevenuenaicelist: action.payload,
        }
        case INCOME_STATEMENT_REVENUE_FAIL:
          return {
            revenueListNaiceLoading: false, error: action.payload
          }
          default:
            return state
  }
}

export const incomeStatementRevenueMultiListReducer = (state = {
  incomerevenuemultilist: []
}, action) => {
  switch (action.type) {
    case INCOME_STATEMENT_REVENUE_REQUEST:
      return {
        revenueListMultiLoading: true, incomerevenuemultilist: []
      }
      case INCOME_STATEMENT_REVENUE_SUCCESS:
        return {
          revenueListMultiLoading: false, incomerevenuemultilist: action.payload,
        }
        case INCOME_STATEMENT_REVENUE_FAIL:
          return {
            revenueListMultiLoading: false, error: action.payload
          }
          default:
            return state
  }
}

export const incomeStatementRevenueAllMultiListReducer = (state = {
  incomerevenueallmultilist: []
}, action) => {
  switch (action.type) {
    case INCOME_STATEMENT_REVENUE_REQUEST:
      return {
        revenueListAllMultiLoading: true, incomerevenueallmultilist: []
      }
      case INCOME_STATEMENT_REVENUE_SUCCESS:
        return {
          revenueListAllMultiLoading: false, incomerevenueallmultilist: action.payload,
        }
        case INCOME_STATEMENT_REVENUE_FAIL:
          return {
            revenueListAllMultiLoading: false, error: action.payload
          }
          default:
            return state
  }
}

// COST OF SALE

export const incomeStatementCostOfSaleListReducer = (state = {
  incomecostofsalelist: []
}, action) => {
  switch (action.type) {
    case INCOME_STATEMENT_COSTOFSALE_REQUEST:
      return {
        cosListLoading: true, incomecostofsalelist: []
      }
      case INCOME_STATEMENT_COSTOFSALE_SUCCESS:
        return {
          cosListLoading: false, incomecostofsalelist: action.payload,
        }
        case INCOME_STATEMENT_COSTOFSALE_FAIL:
          return {
            cosListLoading: false, error: action.payload
          }
          default:
            return state
  }
}

export const incomeStatementCostOfSaleYearMultiListReducer = (state = {
  incomecostofsaleyearmultilist: []
}, action) => {
  switch (action.type) {
    case INCOME_STATEMENT_COSTOFSALE_REQUEST:
      return {
        cosListYearMultiLoading: true, incomecostofsaleyearmultilist: []
      }
      case INCOME_STATEMENT_COSTOFSALE_SUCCESS:
        return {
          cosListYearMultiLoading: false, incomecostofsaleyearmultilist: action.payload,
        }
        case INCOME_STATEMENT_COSTOFSALE_FAIL:
          return {
            cosListYearMultiLoading: false, error: action.payload
          }
          default:
            return state
  }
}

export const incomeStatementCostOfSaleYearListReducer = (state = {
  incomecostofsaleyearlist: []
}, action) => {
  switch (action.type) {
    case INCOME_STATEMENT_COSTOFSALE_REQUEST:
      return {
        cosListYearLoading: true, incomecostofsaleyearlist: []
      }
      case INCOME_STATEMENT_COSTOFSALE_SUCCESS:
        return {
          cosListYearLoading: false, incomecostofsaleyearlist: action.payload,
        }
        case INCOME_STATEMENT_COSTOFSALE_FAIL:
          return {
            cosListYearLoading: false, error: action.payload
          }
          default:
            return state
  }
}

export const incomeStatementCostOfSaleNaiceListReducer = (state = {
  incomecostofsalenaicelist: []
}, action) => {
  switch (action.type) {
    case INCOME_STATEMENT_COSTOFSALE_REQUEST:
      return {
        cosListNaiceLoading: true, incomecostofsalenaicelist: []
      }
      case INCOME_STATEMENT_COSTOFSALE_SUCCESS:
        return {
          cosListNaiceLoading: false, incomecostofsalenaicelist: action.payload,
        }
        case INCOME_STATEMENT_COSTOFSALE_FAIL:
          return {
            cosListNaiceLoading: false, error: action.payload
          }
          default:
            return state
  }
}

export const incomeStatementCostOfSaleMultiListReducer = (state = {
  incomecostofsalesmultilist: []
}, action) => {
  switch (action.type) {
    case INCOME_STATEMENT_COSTOFSALE_REQUEST:
      return {
        cosListMultiLoading: true, incomecostofsalesmultilist: []
      }
      case INCOME_STATEMENT_COSTOFSALE_SUCCESS:
        return {
          cosListMultiLoading: false, incomecostofsalesmultilist: action.payload,
        }
        case INCOME_STATEMENT_COSTOFSALE_FAIL:
          return {
            cosListMultiLoading: false, error: action.payload
          }
          default:
            return state
  }
}

export const incomeStatementCostOfSaleAllMultiListReducer = (state = {
  incomecostofsalesallmultilist: []
}, action) => {
  switch (action.type) {
    case INCOME_STATEMENT_COSTOFSALE_REQUEST:
      return {
        cosListAllMultiLoading: true, incomecostofsalesallmultilist: []
      }
      case INCOME_STATEMENT_COSTOFSALE_SUCCESS:
        return {
          cosListAllMultiLoading: false, incomecostofsalesallmultilist: action.payload,
        }
        case INCOME_STATEMENT_COSTOFSALE_FAIL:
          return {
            cosListAllMultiLoading: false, error: action.payload
          }
          default:
            return state
  }
}

// OPERATING EXPENSES

export const incomeStatementOperatingExpensesListReducer = (state = {
  incomeoperatingexpenseslist: []
}, action) => {
  switch (action.type) {
    case INCOME_STATEMENT_OPERATINGEXPENSES_REQUEST:
      return {
        opListloading: true, incomeoperatingexpenseslist: []
      }
      case INCOME_STATEMENT_OPERATINGEXPENSES_SUCCESS:
        return {
          opListloading: false, incomeoperatingexpenseslist: action.payload,
        }
        case INCOME_STATEMENT_OPERATINGEXPENSESE_FAIL:
          return {
            opListloading: false, error: action.payload
          }
          default:
            return state
  }
}

export const incomeStatementOperatingExpensesYearMultiListReducer = (state = {
  incomeoperatingexpensesyearmultilist: []
}, action) => {
  switch (action.type) {
    case INCOME_STATEMENT_OPERATINGEXPENSES_REQUEST:
      return {
        opListYearMultiLoading: true, incomeoperatingexpensesyearmultilist: []
      }
      case INCOME_STATEMENT_OPERATINGEXPENSES_SUCCESS:
        return {
          opListYearMultiLoading: false, incomeoperatingexpensesyearmultilist: action.payload,
        }
        case INCOME_STATEMENT_OPERATINGEXPENSESE_FAIL:
          return {
            opListYearMultiLoading: false, error: action.payload
          }
          default:
            return state
  }
}

export const incomeStatementOperatingExpensesYearListReducer = (state = {
  incomeoperatingexpensesyearlist: []
}, action) => {
  switch (action.type) {
    case INCOME_STATEMENT_OPERATINGEXPENSES_REQUEST:
      return {
        opListYearLoading: true, incomeoperatingexpensesyearlist: []
      }
      case INCOME_STATEMENT_OPERATINGEXPENSES_SUCCESS:
        return {
          opListYearLoading: false, incomeoperatingexpensesyearlist: action.payload,
        }
        case INCOME_STATEMENT_OPERATINGEXPENSESE_FAIL:
          return {
            opListYearLoading: false, error: action.payload
          }
          default:
            return state
  }
}

export const incomeStatementOperatingExpensesNaiceListReducer = (state = {
  incomeoperatingexpensesnaicelist: []
}, action) => {
  switch (action.type) {
    case INCOME_STATEMENT_OPERATINGEXPENSES_REQUEST:
      return {
        opListNaiceLoading: true, incomeoperatingexpensesnaicelist: []
      }
      case INCOME_STATEMENT_OPERATINGEXPENSES_SUCCESS:
        return {
          opListNaiceLoading: false, incomeoperatingexpensesnaicelist: action.payload,
        }
        case INCOME_STATEMENT_OPERATINGEXPENSESE_FAIL:
          return {
            opListNaiceLoading: false, error: action.payload
          }
          default:
            return state
  }
}

export const incomeStatementOperatingExpensesMultiListReducer = (state = {
  incomeoperatingexpensesmultilist: []
}, action) => {
  switch (action.type) {
    case INCOME_STATEMENT_OPERATINGEXPENSES_REQUEST:
      return {
        opListMultiLoading: true, incomeoperatingexpensesmultilist: []
      }
      case INCOME_STATEMENT_OPERATINGEXPENSES_SUCCESS:
        return {
          opListMultiLoading: false, incomeoperatingexpensesmultilist: action.payload,
        }
        case INCOME_STATEMENT_OPERATINGEXPENSESE_FAIL:
          return {
            opListMultiLoading: false, error: action.payload
          }
          default:
            return state
  }
}

export const incomeStatementOperatingExpensesAllMultiListReducer = (state = {
  incomeoperatingexpensesallmultilist: []
}, action) => {
  switch (action.type) {
    case INCOME_STATEMENT_OPERATINGEXPENSES_REQUEST:
      return {
        opListAllMultiLoading: true, incomeoperatingexpensesallmultilist: []
      }
      case INCOME_STATEMENT_OPERATINGEXPENSES_SUCCESS:
        return {
          opListAllMultiLoading: false, incomeoperatingexpensesallmultilist: action.payload,
        }
        case INCOME_STATEMENT_OPERATINGEXPENSESE_FAIL:
          return {
            opListAllMultiLoading: false, error: action.payload
          }
          default:
            return state
  }
}