export const INCOME_STATEMENT_QUARTILE_REVENUE_REQUEST = "INCOME_STATEMENT_RESET";
export const INCOME_STATEMENT_QUARTILE_REVENUE_SUCCESS = "INCOME_STATEMENT_SUCCESS";
export const INCOME_STATEMENT_QUARTILE_REVENUE_FAIL = "INCOME_STATEMENT_FAIL";

export const INCOME_STATEMENT_REVENUE_REQUEST = "INCOME_STATEMENT_REVENUE_REQUEST";
export const INCOME_STATEMENT_REVENUE_SUCCESS = "INCOME_STATEMENT_REVENUE_SUCCESS";
export const INCOME_STATEMENT_REVENUE_FAIL = "INCOME_STATEMENT_REVENUE_FAIL";

export const INCOME_STATEMENT_COSTOFSALE_REQUEST = "INCOME_STATEMENT_COSTOFSALE_REQUEST";
export const INCOME_STATEMENT_COSTOFSALE_SUCCESS = "INCOME_STATEMENT_COSTOFSALE_SUCCESS";
export const INCOME_STATEMENT_COSTOFSALE_FAIL = "INCOME_STATEMENT_COSTOFSALE_FAIL";


export const INCOME_STATEMENT_OPERATINGEXPENSES_REQUEST = "INCOME_STATEMENT_OPERATINGEXPENSES_REQUEST";
export const INCOME_STATEMENT_OPERATINGEXPENSES_SUCCESS = "INCOME_STATEMENT_OPERATINGEXPENSES_SUCCESS";
export const INCOME_STATEMENT_OPERATINGEXPENSESE_FAIL = "INCOME_STATEMENT_OPERATINGEXPENSES_FAIL";