import { BIZ_NAME_REQUEST,
    BIZ_NAME_SUCCESS,
    BIZ_NAME_FAIL,
    BIZ_NAME_DATA_REQUEST,
    BIZ_NAME_DATA_SUCCESS,
    BIZ_NAME_DATA_FAIL,
    BIZNAME_VALUE_LIST_REQUEST,
    BIZNAME_VALUE_LIST_SUCCESS,
    BIZNAME_VALUE_LIST_FAIL,
  } from "../constants/PremiumBenchMarkMyBusiness";

  export const bizNameListReducer = (state = {
    biznamedatalist: []
  }, action) => {
    switch (action.type) {
      case BIZ_NAME_REQUEST:
        return {
            biznameloading: true, biznamedatalist: []
        }
        case BIZ_NAME_SUCCESS:
          return {
            biznameloading: false,
            biznamedatalist: action.payload,
          }
          case BIZ_NAME_FAIL:
            return {
              biznameloading: false, error: action.payload
            }
            default:
              return state
    }
  }

  export const getbizNamDeataListReducer = (state = {
    getbiznamedatalist: []
  }, action) => {
    switch (action.type) {
      case BIZ_NAME_DATA_REQUEST:
        return {
            getbiznameloading: true, getbiznamedatalist: []
        }
        case BIZ_NAME_DATA_SUCCESS:
          return {
            getbiznameloading: false,
            getbiznamedatalist: action.payload,
          }
          case BIZ_NAME_DATA_FAIL:
            return {
              getbiznameloading: false, error: action.payload
            }
            default:
              return state
    }
  }

  export const myBizNameListReducer = (state = {
    biznamevalue: []
  }, action) => {
    switch (action.type) {
      case BIZNAME_VALUE_LIST_REQUEST:
        return {
          biznamevalue: []
        }
        case BIZNAME_VALUE_LIST_SUCCESS:
          return {
            
            biznamevalue: action.payload,
          }
          case BIZNAME_VALUE_LIST_FAIL:
            return {
              error: action.payload
            }
            default:
              return state
    }
  }