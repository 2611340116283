import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const PremiumCheck = ({ children }) => {
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;

  const userData = window.localStorage.getItem("loginData");
  const userId = JSON.parse(userData);

  useEffect(() => {
    if (!userId) {
      navigate("/signinpage");
    }
  }, []);

  useEffect(() => {
    if (userId && user) {
      if(!user?.subscriptionPlan === "Pro"){
        navigate("/");
      } else if (user?.subscriptionPlan === "Basic" || user?.subscriptionPlan === null){
        navigate("/benchmarkpremiumlist");
      } else {
        navigate("/benchmarkpremium");
      }
    }
  }, [user]);

  return <>{userId && children}</>;
};

export default PremiumCheck;
